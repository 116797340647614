<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="id" v-if="formType === 1">
        <span slot="label" >
          <a-tooltip>
            {{$t('会话.id')}}<template slot="title">{{$t('会话.id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.id"  :placeholder="$t('通用.输入.请输入')+$t('会话.id')" />
      </a-form-model-item>
      <a-form-model-item prop="event" >
        <span slot="label" >
          <a-tooltip>
            {{$t('会话.会话事件')}}<template slot="title">{{$t('会话.会话事件')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.event" :maxLength="64" :placeholder="$t('通用.输入.请输入')+$t('会话.会话事件')" />
      </a-form-model-item>
      <a-form-model-item prop="recordedAt" >
        <span slot="label" >
          <a-tooltip>
            {{$t('会话.记录时间')}}<template slot="title">{{$t('会话.记录时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.recordedAt" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="recordedAtUtcOffset" >
        <span slot="label" >
          <a-tooltip>
            {{$t('会话.记录偏移时间')}}<template slot="title">{{$t('会话.记录偏移时间')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.recordedAtUtcOffset"  :placeholder="$t('通用.输入.请输入')+$t('会话.记录偏移时间')" />
      </a-form-model-item>
      <a-form-model-item prop="sampleInterval" >
        <span slot="label" >
          <a-tooltip>
            {{$t('会话.上报间隔')}}<template slot="title">{{$t('会话.上报间隔')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.sampleInterval" :maxLength="64" :placeholder="$t('通用.输入.请输入')+$t('会话.上报间隔')" />
      </a-form-model-item>
      <a-form-model-item prop="serialNumber" >
        <span slot="label" >
          <a-tooltip>
            {{$t('会话.传感器的ID')}}<template slot="title">{{$t('会话.传感器的ID')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.serialNumber" :maxLength="64" :placeholder="$t('通用.输入.请输入')+$t('会话.传感器的ID')" />
      </a-form-model-item>
      <a-form-model-item prop="uploaded" >
        <span slot="label" >
          <a-tooltip>
            {{$t('会话.是否上传 0:否 1:是')}}<template slot="title">{{$t('会话.是否上传 0:否 1:是')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.uploaded"  :placeholder="$t('通用.输入.请输入')+$t('会话.是否上传 0:否 1:是')" />
      </a-form-model-item>
      <a-form-model-item prop="uuid" >
        <span slot="label" >
          <a-tooltip>
            {{$t('会话.本会话的唯一ID')}}<template slot="title">{{$t('会话.本会话的唯一ID')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.uuid" :maxLength="64" :placeholder="$t('通用.输入.请输入')+$t('会话.本会话的唯一ID')" />
      </a-form-model-item>
      <a-form-model-item prop="userPhoneDeviceNo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('会话.用户手机设备ID')}}<template slot="title">{{$t('会话.用户手机设备ID')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.userPhoneDeviceNo" :maxLength="64" :placeholder="$t('通用.输入.请输入')+$t('会话.用户手机设备ID')" />
      </a-form-model-item>
      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('会话.备注')}}<template slot="title">{{$t('会话.备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('会话.备注')" />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSessions, addSessions, updateSessions } from '@/api/sessions/sessions'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        event: null,

        recordedAt: null,

        recordedAtUtcOffset: null,

        sampleInterval: null,

        serialNumber: null,

        uploaded: null,

        uuid: null,

        userPhoneDeviceNo: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        id: [
          { required: true, message: this.$t('会话.id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        event: null,
        recordedAt: null,
        recordedAtUtcOffset: null,
        sampleInterval: null,
        serialNumber: null,
        uploaded: null,
        uuid: null,
        userPhoneDeviceNo: null,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSessions({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateSessions(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addSessions(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
