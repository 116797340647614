import request from '@/utils/request'


// 查询会话列表
export function listSessions(query) {
  return request({
    url: '/sessions/sessions/list',
    method: 'get',
    params: query
  })
}

// 查询会话分页
export function pageSessions(query) {
  return request({
    url: '/sessions/sessions/page',
    method: 'get',
    params: query
  })
}

// 查询会话详细
export function getSessions(data) {
  return request({
    url: '/sessions/sessions/detail',
    method: 'get',
    params: data
  })
}

// 新增会话
export function addSessions(data) {
  return request({
    url: '/sessions/sessions/add',
    method: 'post',
    data: data
  })
}

// 修改会话
export function updateSessions(data) {
  return request({
    url: '/sessions/sessions/edit',
    method: 'post',
    data: data
  })
}

// 删除会话
export function delSessions(data) {
  return request({
    url: '/sessions/sessions/delete',
    method: 'post',
    data: data
  })
}
